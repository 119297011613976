.ant-custom-input,
.ant-input-password {
  font-weight: 400;
  font-size: 14px;
  color: #8b8b8b;
  height: 44px;
  margin-bottom: 0;
  border-radius: 8px !important;
  padding: 0 11px;
  font-family: 'Open-Sans', sans-serif !important;
}
.ant-input-password {
  padding: 0 !important;
  padding-right: 5px !important;
  input {
    height: 42px;
    padding-left: 5px !important;
    margin-left: 6px;
  }
}

.ant-custom-area {
  border-radius: 15px;
}
