#login-form {
  .hide-password {
    input {
      &[type='password'] {
        font-size: 32px;
      }

      &::placeholder {
        font-size: 16px !important;
      }
    }
  }
  .ant-custom-input,
  .ant-input-password {
    font-weight: 400;
    font-size: 14px;
    color: #8b8b8b;
    height: 44px;
    margin-bottom: 0;
    border-radius: 0px;
    padding: 0 11px;
    font-family: 'Open-Sans', sans-serif !important;
  }
  .ant-input-password {
    padding: 0 !important;
    .ant-input-suffix {
      margin-right: 10px;
    }
    input {
      height: 42px;
      padding-left: 11px !important;
      margin-left: 0;
    }
  }

  .forgot-password-security {
    &-step {
      display: flex;
      align-items: center;
      font-family: 'Open-Sans';
      margin-top: 24px;
      margin-bottom: 24px;
      gap: 10px;
      font-size: 14px;
      font-weight: 400;
      color: #585858;
    }
  }

  .ant-input-password {
    border-radius: unset !important;
  }

  .form-name {
    max-width: 400px;

    .form-name-header {
      font-size: 14px;
      font-weight: 700;
      color: $secondary_color;
    }

    p {
      font-family: 'Open-Sans' !important;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      width: 400px;
      color: $text_cancel_btn;

      span {
        font-weight: 600;
        color: $text_black_light;
      }
    }

    div {
      width: 72px;
      height: 2px;
      background: $primary_color;
    }
  }

  .form-data {
    min-height: 50%;
  }

  .send-code-noti {
    font-size: 14px;
    font-weight: 400;
    color: $text_placeholder;
    margin-top: 16px;

    strong {
      color: $text_black;
      font-weight: 500;
    }
  }

  .confirm-code {
    .confirm-code-input {
      display: flex;
      align-items: center;

      input,
      .otp-input {
        width: 42px !important;
        height: 42px !important;
        background-color: #fff;
        font-size: 14px;
        font-weight: 600;
        line-height: 36;
        color: #d82c1c;
        margin: 0 6px;
        border: 1px solid #d9d9d9 !important;
        border-radius: 4px !important;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        &:first-child {
          margin-left: 0;
        }
      }
    }

    .confirm-code-messenger {
      margin-top: 16px;
      margin-bottom: 0;
      color: #000;

      span {
        cursor: pointer;
      }
    }
  }

  .btn-fogot-success {
    margin-top: 0;
  }

  .ant-result-success {
    .ant-result-subtitle {
      font-size: 14px;
      line-height: 24px;
      color: $text_black_grey;
    }
  }

  .txt-back-login {
    font-size: 14px;
    font-weight: 400;
    color: $text_black;
    margin-top: 16px;
    cursor: pointer;
    position: absolute;
    bottom: -149px;
    left: 0;
  }
  .btn-backstep {
    border: unset !important;
    background: transparent !important;
    width: unset !important;
    height: unset !important;
    padding: 0 !important;
    margin: 0 0 20px 0 !important;
  }
}

.ant-result {
  .ant-result-subtitle {
    max-width: 60%;
    text-align: center;
    margin: 0 auto;
    font-size: 14px;
    color: $text_black_grey;
  }

  .ant-result-title {
    color: $secondary_color;
    font-weight: 700;
    margin-top: 40px;
  }
}
