#lesson {
  .lesson-checkbox {
    .ant-checkbox-inner {
      width: 24px !important;
      height: 24px !important;
      border-color: #8b8b8b !important;
      border-width: 2px;
    }
  }
}
