#role-management {
  .role-management__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    &__title {
      font-weight: 700;
      font-size: 32px;
      line-height: 36px;
      color: $primary_color;
    }
  }

  .role-management__filter {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;

    .input-search {
      width: 350px;
    }

    .select-status {
      width: 184px;
      height: auto !important;
      .ant-select-selection-item {
        display: flex;
        align-items: center;
      }

      .ant-select-selection-search {
        input {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }

      .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
      }
    }

    .dropdown-location:hover {
      color: inherit;
      background-color: inherit;
    }
  }

  .input-search {
    width: 350px;
    margin-bottom: 0;
  }

  .button-add {
    padding: 12px 16px 12px 16px;
    height: 48px;
    border: unset;
    display: flex;
    align-items: center;
    background-color: $primary_color;
    border-radius: 2px;
    color: $text_white;
  }

  .action-role {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    svg {
      cursor: pointer;
    }
  }
}

#create-role-management {
  .create-role-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    color: $text_black_light;
    margin-bottom: 10px;
  }

  .form-create-role {
    display: flex;
    align-items: center;
    gap: 15px;

    .code {
      width: 194px;
    }

    .role {
      width: 397px;
    }

    .ant-form-item {
      margin-bottom: 10px;

      .ant-form-item-label {
        padding: unset;

        label {
          font-size: 14px;
          font-weight: 700;
          line-height: 22px;
          color: $text_black_light;
        }
      }
    }
  }

  .button-action {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .more-action {
      display: flex;
      flex-direction: column;
    }

    .button-create,
    .button-save {
      width: 436px;
      background-color: $primary_color;
      color: $text_white;
      border: 1px solid $primary_color !important;
      //border-radius: unset !important;
    }

    .button-delete {
      margin-top: 15px;
      width: 436px;
      box-shadow: unset;
      color:  $primary_color !important;
      border: 1px solid $primary_color !important;
      //border-radius: unset !important;
    }
  }
}
