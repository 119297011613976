@import './variables.scss';
@import './pages/index.scss';
@import './components/components.scss';
@import './global.scss';
body {
  font-family: 'Open-Sans' !important;
}

.font-base {
  font-family: 'Open-Sans' !important;
}
