.modal-category {
  width: 608px !important;

  &__content {
    padding: 5px 18px;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 25px;

      &__title {
        font-size: 14px;
        font-weight: 700;
        line-height: 36px;
        color: $text_black;
        margin-bottom: 10px;
      }

      &__close {
        cursor: pointer;
      }
    }

    .ant-form-item-row {
      flex-direction: column;

      .ant-form-item-label {
        text-align: start;
      }
    }

    &__rows {
      display: flex;
      align-items: flex-end;
      gap: 25px;

      .icon {
        border: 1px solid $border_input_color;
        border-radius: 16px;
        width: 112px;

        &__display {
          width: 64px;
          height: 64px;
          border-radius: 50%;
          // border: 1px solid $border_input_color;
          margin: 10px auto;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $background_blue_light;

          img {
            max-height: 100%;
            max-width: 100%;
          }
        }

        &__line {
          margin: unset;
        }

        &__upload {
          margin-bottom: unset !important;
          display: flex;
          justify-content: center;

          .icon__text {
            display: flex;
            gap: 7px;
          }
        }
      }

      .name {
        // width: 75%;
        flex: 1;
      }

      .quantity {
        width: 25%;
      }

      .unit,
      .status {
        width: 50%;
      }
    }

    .ant-form-item {
      margin-bottom: 0px;

      .ant-form-item-label {
        padding: unset;

        label {
          font-size: 14px;
          font-weight: 700;
          line-height: 22px;
          color: $text_black_light;
        }
      }
    }

    &__action {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;

      .button-submit {
        background-color: $primary_color;
        color: $text_white !important;
        border: unset;
      }

      .button-cancel {
        color: $text_black_grey !important;
        border: unset;
        box-shadow: none;
      }

      .button-delete {
        color: $text_warning;
        border: unset;
        box-shadow: unset;
      }
    }
  }
}
