#chart-admin {
  .chart-box-rate {
    height: 209px;

    .top-trainer-containter {
      overflow-x: auto;
      height: 130px;
      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #00297a;
        border-radius: 8px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $primary_color;
        border-radius: 8px;
      }
    }

    .apexcharts-canvas {
      margin-left: -30px;
    }
  }
}
