.modal-provide-medicine {
  width: 608px !important;
  &__content {
    padding: 5px 18px;

    &__title {
      font-size: 14px;
      font-weight: 700;
      line-height: 36px;
      color: $text_black;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;

      &__button-close:hover {
        cursor: pointer;
      }
    }

    &__rows {
      display: flex;
      align-items: flex-start;
      gap: 25px;

      .name {
        width: 70%;
        .ant-select-selection-search {
          display: flex;
          align-items: center;
        }
      }

      .quantity {
        width: 30%;
      }
      .guide {
        width: 100%;
      }
    }
    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }

    .ant-form-item {
      margin-bottom: 10px;

      .ant-form-item-row {
        flex-direction: column;
      }

      .ant-form-item-label {
        text-align: start;
        padding: unset;

        label {
          font-size: 14px;
          font-weight: 700;
          line-height: 22px;
          color: $text_black_light;

          &:after {
            content: none;
          }
        }
      }
    }

    &__action {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;

      .button-submit {
        background-color: $primary_color;
        color: $text_white !important;
        border: unset;
      }

      .button-cancel {
        color: $text_black_grey !important;
        border: unset;
        box-shadow: none;
      }

      .button-delete {
        color: $text_warning;
        border: unset;
        box-shadow: unset;
      }
    }
  }
}
