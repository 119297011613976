.modal-sample-prescription {
  width: 608px !important;

  &__content {
    padding: 5px 18px;

    &__title {
      font-size: 14px;
      font-weight: 700;
      line-height: 36px;
      color: $text_black;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;

      &__button-close:hover {
        cursor: pointer;
      }
    }

    &__rows {
      display: flex;
      align-items: center;
      gap: 25px;
      padding-right: 5px;

      .search-medicine {
        width: 100%;
      }

      .prescription-list {
        width: 524px;
        flex-direction: column;
        display: flex;
        gap: 15px;
        overflow: auto;
        height: 500px;
        padding-right: 3px;

        .prescription-item {
          display: flex;
          height: 104px;
          width: 100%;
          border-radius: 12px;
          border: 1px solid $border_input_color;
          padding: 8px 12px;
          gap: 12px;

          &__button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 4%;
          }

          &__wrap {
            display: flex;
            flex-direction: column;
            width: 96%;
            height: 100%;
            justify-content: space-between;
            align-items: stretch;

            &__content {
              display: flex;
              justify-content: space-between;
              gap: 5px;

              &__title {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #666666;
                white-space: pre;
              }

              &__content {
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                color: $text_black_light;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              &__content-mid {
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                color: $secondary_color;
                width: 200px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: right;
              }
            }
          }
        }
      }

      .prescription-list::-webkit-scrollbar {
        width: 5px;
      }

      .prescription-list::-webkit-scrollbar-thumb {
        background-color: $primary_color;
        border-radius: 5px;
      }
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }

    .ant-form-item {
      margin-bottom: 10px;

      .ant-form-item-row {
        flex-direction: column;
      }

      .ant-form-item-label {
        text-align: start;
        padding: unset;

        label {
          font-size: 14px;
          font-weight: 700;
          line-height: 22px;
          color: $text_black_light;

          &:after {
            content: none;
          }
        }
      }
    }

    &__action {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;

      .button-submit {
        background-color: $primary_color;
        color: $text_white !important;
        border: unset;
      }
    }
  }
}
