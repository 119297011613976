.modal-add-medicine {
  width: 608px !important;

  .ant-row {
    display: unset;
  }

  .ant-form-item-label > label::after {
    display: none;
  }

  .ant-select-selection-search {
    display: flex;
    align-items: center;
  }

  &__content {
    padding: 5px 18px;

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      line-height: 36px;
      color: $text_black;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__rows {
      display: flex;
      align-items: flex-start;
      gap: 25px;

      .name {
        width: 75%;
      }

      .quantity {
        width: 25%;
      }
    }

    .ant-form-item {
      margin-bottom: 10px;

      .ant-form-item-label {
        padding: unset;
        display: inline-block;

        label {
          font-size: 14px;
          font-weight: 700;
          line-height: 22px;
          color: $text_black_light;
        }
      }
    }

    &__action {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;

      .button-submit {
        background-color: $primary_color;
        color: $text_white !important;
        border: unset;
      }

      .button-cancel {
        color: $text_black_grey !important;
        border: unset;
        box-shadow: none;
      }
    }
  }
}
