#doc-management {
  overflow: auto;

  .doc-management__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    &__title {
      font-weight: 700;
      font-size: 14px;
      line-height: 36px;
      color: $text_black_light;
    }

    .button-add {
      width: 191px;
      border: unset;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $primary_color;
      color: $text_white;
    }
  }

  .doc-management__filter {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;

    .input-search {
      width: 350px;
    }

    .select-status {
      width: 184px;

      .ant-select-selection-item {
        display: flex;
        align-items: center;
      }

      .ant-select-selection-search {
        input {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }

      .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
      }
    }

    .dropdown-location:hover {
      color: inherit;
      /* Sử dụng màu văn bản mặc định */
      background-color: inherit;
      /* Sử dụng màu nền mặc định */
    }
  }

  .table-doc {
    .ant-table-tbody {
      tr {
        .ant-table-cell {
          word-break: break-word;
          height: 57px;

          .title-doc {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }

          img {
            margin: auto;
          }
        }

        td:first-child {
          display: flex;
        }
      }
    }
  }

  .image-doc {
    max-width: 100%;
    max-height: 100%;
  }

  .content-doc {
    max-height: 3 * (25px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    img {
      max-width: 100%;
    }

    p {
      margin-bottom: 0px;
    }
  }

  .manager-doc {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .status-doc {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .action-doc {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    svg {
      cursor: pointer;
    }
  }
}

#create-doc-management {
  .container-doc {
    display: flex;
    gap: 25px;

    .left-container {
      width: 60%;
      position: relative;

      .tox-tinymce {
        height: 75vh !important;
      }

      .ck-editor__editable {
        height: 75vh;
      }

      .ck-toolbar {
        border-radius: 6px 6px 0 0;
      }

      .ck-content {
        border-radius: 0 0 6px 6px;
      }
    }

    .loading-img-editor {
      .ant-spin {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 100;
      }

      .ck-editor__editable {
        opacity: 0.6;
      }
    }

    .content-error {
      .tox-tinymce {
        border: 1px solid $text_error;
      }

      .ck-toolbar,
      .ck-editor__editable {
        border: 1px solid $text_error;
      }
    }

    .title-error,
    .image-error {
      border: 1px solid $text_error !important;
    }

    .right-container {
      width: 40%;

      &__content {
        border: 1px solid $border_input_color;
        border-radius: 24px;
        padding: 15px 20px 25px 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;

        &__image {
          width: 100%;
          height: 380px;
          border: 1px solid $border_input_color;
          border-radius: 24px;
          display: flex;
          align-items: center;
          justify-content: center;

          &__display {
            position: relative;
            height: 100%;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            border-radius: 24px;
            overflow: auto;
            gap: 8px;
            padding: 10px;

            &::-webkit-scrollbar {
              width: 4px;
              height: 4px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background: #00297a;
              border-radius: 8px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: $primary_color;
              border-radius: 8px;
            }

            &-item {
              width: 240px;
              height: fit-content;
              border: 1px solid #d9d9d9;
              border-radius: 8px;
              padding: 5px 8px;

              p {
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              &-action {
                display: flex;
                justify-content: space-between;
                align-items: center;

                &-download {
                  border: unset;
                  background: unset;
                  box-shadow: unset;
                  padding: 0;
                  margin: 0;
                }
              }
            }

            img {
              max-height: 100%;
              max-width: 100%;
              margin: auto;
            }

            span {
              position: absolute;
              right: 5px;
              top: 5px;
              cursor: pointer;
            }
          }

          &__upload {
            .avatar-uploader {
              .ant-upload {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .text-upload {
                  color: $primary_color;
                  cursor: pointer;
                }
              }
            }
          }
        }

        &__title {
          &__label {
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
          }
        }

        &__status {
          display: flex;
          align-items: center;
          gap: 10px;

          &__label {
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
          }
        }
      }

      .custom-label {
        margin-top: -10px;
      }

      &__action {
        margin-top: 25px;

        .more-action {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        .button-create,
        .button-save {
          width: 100%;
          background-color: $primary_color;
          color: $text_white;
          border: unset;
        }

        .button-delete {
          width: 100%;
          border: unset;
          box-shadow: unset;
          color: $text_warning;
        }

        .button-cancel {
          width: 100%;
          border: unset;
          box-shadow: unset;
          color: unset;
        }
      }
    }
  }
}
