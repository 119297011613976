#customer-management {
  .customer-management__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    &__title {
      font-weight: 700;
      font-size: 32px;
      line-height: 36px;
      color: $text_black_light;
    }

    .button-add {
      width: 227px;
      border: unset;
      display: flex;
      align-items: center;
      background-color: $primary_color;
      color: $text_white;
    }
  }

  .custom-table {
    .ant-table-thead > tr > th {
      font-size: 14px;
      font-weight: 700;
    }
  }

  .customer-management__filter {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;

    .input-search {
      width: 350px;
    }

    .select-status {
      width: 184px;
      height: auto !important;
      .ant-select-selection-item {
        display: flex;
        align-items: center;
      }

      .ant-select-selection-search {
        input {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }

      .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
      }
    }

    .dropdown-location:hover {
      color: inherit;
      background-color: inherit;
    }
  }

  .manager-customer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .status-customer {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .action-customer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
}

#create-customer-management {
  .create-customer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title {
      font-size: 14px;
      font-weight: 700;
      line-height: 36px;
      color: $text_black_light;
      margin-bottom: 10px;
    }

    .button-chat {
      background-color: $primary_color;
      color: $text_white;
    }
  }

  .form-create-customer {
    margin-top: 20px;
    display: flex;
    // align-items: center;
    gap: 15px;
    max-width: 100% !important;

    .ant-form-item {
      .ant-form-item-row {
        .ant-form-item-label {
          label {
            color: $text_black_light;
            font-family: 'Open-sans';
            font-size: 14px;
            font-weight: 700;
            line-height: 22px;
          }
        }

        .ant-form-item-control-input-content {
          input {
            font-size: 14px;
          }
        }
      }
    }

    .customer-info {
      padding: 20px 15px;
      width: 70%;
      border: 1px solid $border_input_color;
      border-radius: 24px;

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &__title {
          font-size: 14px;
          font-weight: 700;
          line-height: 32px;
          color: $secondary_color;
        }

        &__switch {
          display: flex;
          align-items: center;
          gap: 10px;

          &__label {
            color: $secondary_color;
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
          }
        }
      }

      &__content {
        margin-top: 25px;
        display: flex;
        justify-content: space-between;
        gap: 25px;

        &__avatar {
          width: 30%;
          height: fit-content;
          position: relative;
        }

        &__info {
          width: 70%;

          &__rows {
            display: flex;
            align-items: flex-start;
            gap: 15px;
            width: 100%;
          }

          .name {
            width: 70%;
          }

          .code {
            width: 30%;
          }

          .dob {
            width: 50%;

            .ant-picker {
              width: 100%;
              height: 48px;
              border-radius: 24px;
            }
          }

          .email,
          .phone,
          .gender,
          .status,
          .package {
            width: 50%;
          }
        }
      }
    }

    .button-action {
      width: 30%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

      .more-action {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      .button-create,
      .button-save {
        width: 100%;
        background-color: $primary_color;
        color: $text_white;
        border: unset;
      }

      .button-delete {
        width: 100%;
        border: unset;
        box-shadow: unset;
        color: $text_warning;
      }

      .button-cancel {
        width: 100%;
        border: unset;
        box-shadow: unset;
        color: unset;
      }
    }
  }
}
