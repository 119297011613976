#login-form {
  font-family: 'Inter', 'Open-Sans';
  box-shadow: 2px 4px 40px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100vh;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  position: relative;

  button {
    height: 48px;
    font-size: 16px;
    font-weight: 600;
    margin-top: 32px;
    font-family: 'Open-Sans';
    border-radius: 2px !important;
    background: $primary_color;
  }

  input {
    font-size: 16px;
    font-family: 'Open-Sans';
    height: 48px;
    border-radius: unset !important;
    width: 387px;
  }

  label {
    font-size: 14px;
    font-weight: 700;
    font-family: 'Open-Sans';
  }

  .title-login-form {
    font-size: 32px;
    font-weight: 700;
    color: $primary_color;
    margin: auto auto 81px auto;
  }

  .register-link {
    font-size: 16px;
    font-weight: 400;
    color: $text_placeholder;
    margin-bottom: 48px;

    span {
      color: $primary_color;
      cursor: pointer;
    }
  }

  form {
    margin-left: 161px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logo {
    display: flex;
    justify-content: center;
    width: 50%;
    background-image: url('../../../public/assets/images/background-login-form.png');
    background-size: cover;

    &-login {
      position: absolute;
      top: 50%;
      width: 554px;
      height: 264px;
      transform: translate(0, -50%);
    }
  }

  .title {
    max-width: 350px;
    font-size: 24px;
    font-weight: 700;
    color: $secondary_color;
    margin: auto auto 32px auto;

    div {
      border-bottom: 2px solid $primary_color;
      padding-bottom: 8px;
      padding-right: 6px;
    }
  }

  //.ant-input-password,
  //#basic_username {
  //  height: 48px;
  //  border-radius: unset !important;
  //  width: 387px;
  //}

  .form-item-remember {
    margin-bottom: 0 !important;

    &-title {
      font-size: 14px;
      font-weight: 400;
      color: $text_placeholder;
      margin-bottom: 0;
      font-family: 'Open-Sans';
    }
  }

  .form-item-password {
    margin-bottom: 12px !important;
  }

  .txt-forgot {
    font-size: 16px;
    color: $primary_color;
    font-weight: 400;
    font-family: 'Open-Sans';
    cursor: pointer;
  }
}
