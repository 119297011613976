.ant-custom-button {
  border-radius: 2px;
  height: 56px;
  min-width: 127px;
  font-family: 'Open-Sans' !important;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}
