@import './style/index.scss';

html,
body {
  font-family: 'Open-Sans', 'Open-Sans-Italic' !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cursor-pointer {
  cursor: pointer;
}

@font-face {
  font-family: 'Open-Sans';
  src: url('../public/font/OpenSans-VariableFont.ttf');
}

@font-face {
  font-family: 'Open-Sans-Italic';
  src: url('../public/font/OpenSans-Italic-VariableFont.ttf');
}

@media screen and (max-width: 991px) {
  .ant-drawer-content-wrapper {
    width: 280px !important;
  }

  .ant-layout-sider {
    width: 0 !important;
    min-width: 0 !important;
    flex: 0 0 auto !important;
  }

  .ant-drawer-body {
    padding: 0px !important;
  }

  .ant-layout-content {
    padding: 10px !important;
  }
}
