.confirm-delete-modal {
  width: 352px;
  height: 168px;
  border-radius: 0px;
  // font-family: Open-Sans;
  display: none;
  .ant-modal-content {
    border-radius: 0px;
    padding: 20px 37px;
  }
}
