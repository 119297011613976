.breadcrumb-container {
  margin-left: 30px;
  font-family: Open-Sans;
  a {
    text-decoration: none;
  }

  span.ant-breadcrumb-link {
    color: #702a14;
    font-weight: 700;
  }
  a.ant-breadcrumb-link {
    color: #4c4c4c;
  }
  .icon-home {
    img {
      margin-right: 8px;
    }
    color: #4c4c4c;
    font-family: Open-Sans;
  }
}

.ant-dropdown .ant-dropdown-menu {
  padding: 24px 25px 40px 20px;
}

span.ant-dropdown-menu-title-content {
  font-family: Open-Sans;
}
